import * as React from "react";

import Layout from '../components/layout.js';

const IndexPage = ({ location }) => {

  return (
    <Layout location={location}>
      <div class="d-none">
              {`##########################################%        
          ######################################################(  
        *######@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@###### 
        #####@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#####
        #####@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@####
        (((((@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#(((
        (((((@@@@@@@//////@@@@@@@/////@@@@@@@@@@@@@/////@@@@@@@@/(((
        (((((@@@@@@////////@@@@@@///////@@@@@@@@@@///////@@@@@@@/(((
        (((((@@@@@@@////////@@@@@@///////@@@@@@@@///////@@@@@@@@/(((
        (((((@@@@@@@@((((((((@@@@@@(((((((@@@@@@(((((((@@@@@@@@@#(((
        (((((@@@@@@@@@((((((((@@@@%((((((((@@@@(((((((@@@@@@@@@@#(((
        (((((@@@@@@@@@@((((((((@@/((((((((((@@(((((((@@@@@@@@@@@#(((
        (((((@@@@@@@@@@@(((((((/@(((((((((((((((((((@@@@@@@@@@@@#(((
        (////@@@@@@@@@@@@/////////////@////////////@@@@@@@@@@@@@#///
        /////@@@@@@@@@@@@@///////////@@@//////////@@@@@@@@@@@@@@#///
        /////@@@@@@@@@@@@@@/////////@@@@@////////@@@@@@@@@@@@@@@(///
        /////@@@@@@@@@@@@@@@///////@@@@@@///////@@@@@@@@@@@@@@@@(///
        /////@@@@@@@@@@@@@@@@/////@@@@@@@@%////@@@@@@@@@@@@@@@@@(///
        /////@@@@@@@@@@@@@@@@@///@@@@@@@@@@#//%@@@@@@@@@@@@@@@@@(///
        /////@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@(///
        /////@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@////
        /////@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@/////
          ///////@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@/////// 
          //////////////////////////////////////////////////////#`}
      </div>
    </Layout>
  )
}

export default IndexPage 